<template>
    <ValidationProvider rules="required" name="Загрузить иконку" tag="div" class="upload-photo">
        <div class="upload-photo-slot" slot-scope="{ valid, errors }">
            <img class="upload-img" v-if="iconSrc" :src="iconSrc" alt="">
            <b-form-file
                accept=".jpg, .png, .jpeg"
                placeholder="Загрузить иконку"
                drop-placeholder="Перетащите файл сюда"
                v-model="icon"
                :state="getStateError(valid, errors)"
            >
                <template slot="file-name">
                    <b-badge>Обновить иконку</b-badge>
                </template>
            </b-form-file>
        </div>
    </ValidationProvider>
</template>

<script>
    import { errorsAlert } from '@/helpers/errorsAlert'

    const base64Encode = data =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

    export default {
        name: "UploadPhoto",
        props: ['value'],
        methods: {
            getStateError(valid, errors){
                this.$emit('error', errors[0]);
                return errors[0] ? false : (valid ? true : null)
            }
        },
        watch: {
            value(icon){
                if(icon && typeof icon === 'string'){
                    this.iconSrc = icon
                }
                if(!icon){
                    this.iconSrc = null;
                    this.icon = null;
                }
            },
            icon(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        // Проверяем на максимальный размер картинку
                        const size = newValue.size;
                        const maxSize = 2097152;
                        if(size >= maxSize){
                            this.icon = null;
                            errorsAlert('File too large. Max 2 MB');
                            return
                        }

                        base64Encode(newValue)
                            .then(value => {
                                this.iconSrc = value;
                                this.$emit('input', newValue);
                            })
                            .catch(() => {
                                this.iconSrc = null;
                            });
                    } else {
                        this.iconSrc = null;
                    }
                }
            },
        },
        data(){
            return{
                icon: null,
                iconSrc: null,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .upload-photo{
        width: 152px;
        height: 152px;
        &-slot{
            height: 100%;
        }
    }
</style>
<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="h1">Иконки для материалов</div>
            <div class="underline mb-4">
                <ValidationObserver ref="Icon">
                    <b-form slot-scope="{ validate, errors }" @submit.prevent="handleSubmit">
                        <div class="icon-box">
                            <div class="icon-box__img">
                                <upload-icon v-model="uploadIcon" @error="getIconError"/>
                            </div>
                            <div class="icon-box__desc">
                                <div class="row">
                                    <ValidationProvider rules="required|min:3|max:100" name="Название иконки" tag="div" class="col-md-6">
                                        <div class="form-group" slot-scope="{ valid, errors }">
                                            <b-form-input
                                                v-model="title"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                placeholder="Название иконки"
                                            ></b-form-input>
                                            <b-form-invalid-feedback>
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </div>
                                    </ValidationProvider>
                                    <div class="col-md-6">
                                        <b-button type="submit" variant="primary submit pl-5 pr-5">Добавить</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="iconErrorMess" class="invalid-feedback">
                           {{iconErrorMess}}
                        </div>
                    </b-form>
                </ValidationObserver>
            </div>
            <div class="mb-2">Список иконок</div>
            <b-overlay class="icon-list" :show="icons.length ? false : true" rounded="sm">
                <div class="icon-item" v-for="(icon, index) in icons" :title="icon.title">
                    <img :src="icon.icon" alt="">
                    <div v-if="index > 3" class="delete-btn" @click="handleDeleteIcon(icon._id)">удалить</div>
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import UploadIcon from "@/components/forms/UploadIcon";
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'

    export default {
        name: "icons",
        components: {
            UploadIcon
        },
        methods: {
            ...mapActions([
                'getIconList',
                'deleteIconById',
                'saveIcon'
            ]),
            getIconError(error){
                if(error){
                    this.iconErrorMess = error;
                } else {
                    this.iconErrorMess = '';
                }
            },
            handleDeleteIcon(id){
                this.$swal({
                    icon: 'warning',
                    text: 'Вы действительно хотите удалить иконку?',
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteIconById(id)
                    }
                })
            },
            async handleSubmit(){
                const isValid = await this.$refs.Icon.validate();
                if(isValid){
                    const data = {
                        icon: this.uploadIcon,
                        title: this.title
                    };
                    this.saveIcon(data).then(() => {
                        this.uploadIcon = null;
                        this.title = null;

                        this.$nextTick(() => {
                            this.$refs.Icon.reset();
                        })
                    })
                    .catch(error => {
                        errorsAlertApi(error);
                    });
                }
            }
        },
        computed: {
            ...mapGetters([
                'icons'
            ]),
        },
        created(){
            this.getIconList()
        },
        data(){
            return{
                uploadIcon: null,
                iconErrorMess: '',
                title: null,
                errorsMess: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .icon-box{
        display: flex;
        align-items: center;
        &__img{
            width: 152px;
            flex: none;
        }
        &__desc{
            flex: 1;
            padding: 0 0 0 30px;
        }
    }
    .icon-list{
        display: flex;
        flex-wrap: wrap;
    }
    .icon-item{
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background: #f0f0f0;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 7px 5px;
        position: relative;
        img{
            max-width: 100%;
        }
        &:hover{
            .delete-btn{
                opacity: 1;
            }
        }
    }
    .delete-btn{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(240, 240, 240, 0.8);
        font-size: 12px;
        text-transform: uppercase;
        color: #2c68b8;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.2s linear;
        position: absolute;
        top:0;
        left: 0;
        cursor: pointer;
    }
    .invalid-feedback{
        display: block;
    }
</style>
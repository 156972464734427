import Swal from 'sweetalert2'

export function errorsAlert(error) {
    Swal.fire({
        title: 'Ошибка!',
        icon: 'error',
        confirmButtonText: 'Ok',
        text: error
    });

    return error
}